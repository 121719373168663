import React, { useEffect, useRef } from 'react'
import {Deutsch, English, Type, LinkABOUT,LinkWORK, LinkHOME, LinkDE, LinkEN, CLink, Page, SiteTitle, FirstRenderTypist} from '../components/linksetc.js'
import Layout from '../components/layout'
import { Link } from 'gatsby'


var first_render = false

const IndexPage = ({ location }) => {
  
  useEffect(()=>{
    window.rendered="true"
    first_render = window.rendered
  }, [] 
  ) 
  
  
  return(

  <Layout>
        

  <SiteTitle string="Kevin Bloch | Freelance Copywriter Zürich | Schweiz | UX, Digitale Projekte" />
  <meta name="description" content="Freelance Texter/Copywriter/Konzepter in Zürich für UX und digitale Projekte. Branding und Marketing. English und Deutsch." />

      <Page location={location}>


        
          <English Language={location.search}>
            <FirstRenderTypist rendered={first_render} >
                This is the website of former freelance copywriter <LinkABOUT location={location}>Kevin Bloch</LinkABOUT>.
                Thanks for all the <LinkWORK location={location}>projects</LinkWORK>. Say <a href="mailto:hello@kevinbloch.ch">hello@kevinbloch.ch</a>. 
                I wrote in English and <LinkDE location={location}>German</LinkDE>.
            </FirstRenderTypist>
          </English>

          <Deutsch Language={location.search}>
          <FirstRenderTypist rendered={first_render} >
                Dies ist die Webseite des ehemaligen freiberuflichen Texters <LinkABOUT location={location}>Kevin Bloch</LinkABOUT>.
                Danke für all die <LinkWORK location={location}>Projekte</LinkWORK> und <Link to="/quotes">Erlebnisse</Link>. <a href="mailto:schreibe@kevinbloch.ch">schreibe@kevinbloch.ch</a>,
                auf Deutsch und <LinkEN location={location}>Englisch</LinkEN>.
            </FirstRenderTypist>
          </Deutsch>





      </Page>
      </Layout>

)}

export default IndexPage
